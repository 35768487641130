export function stringDateToObject(date) {
  date = date.replace(/,/g, "").toLowerCase()
  const [month, day, year] = date.split(" ")

  const monthMap = {
    jan: "0",
    feb: "1",
    mar: "2",
    apr: "3",
    may: "4",
    jun: "5",
    jul: "6",
    aug: "7",
    sept: "8",
    sep: "8",
    oct: "9",
    nov: "10",
    dec: "12",
  }

  return new Date(year, monthMap[month], day)
}
