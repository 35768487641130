import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import RevealNews from "./reveal-news"
import { stringDateToObject } from "../../utils"

const NewsPosts = () => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      wpgraphql {
        cptNews (first: 500) {
          nodes {
            title
            excerpt
            featuredImage {
              node {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            acf_news {
              newsDate
              buttonText
              buttonUrl
            }
          }
        }
      }
    }
  `)

  data.wpgraphql.cptNews.nodes = data.wpgraphql.cptNews.nodes.sort((a, b) => {
    return (
      stringDateToObject(b.acf_news.newsDate).getTime() -
      stringDateToObject(a.acf_news.newsDate).getTime()
    )
  })

  return (
    <article>
      {data.wpgraphql.cptNews.nodes.map((items, i) => (
        <RevealNews items={items} key={i}></RevealNews>
      ))}
    </article>
  )
}

export default NewsPosts
